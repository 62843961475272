import { PropsWithoutRef } from "react";

type FooterProps = PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export const Footer = ({ ...props }: FooterProps) => {
  return <footer className="text-center text-xl text-yellow-500">
    <p className="text-[14px] text-black bg-[#e9b306] mt-[10px] p-2">Liability limited by a scheme approved under Professional Standards Legislation</p>
    <p className="py-4">Copyright by {new Date().getFullYear()} Taxzone, Inc</p>
  </footer>;
};
